import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Helmet from 'react-helmet'
import FAQs from '../components/FAQs'

export const HelpPageTemplate = ({
  helmet,
  image,
  title,
  heading,
  description,
  faq,
}) => (
  <div className="content">
    
    {helmet || ''}

    <div
      className="full-width-image-container margin-top-0"
      style={{
        backgroundImage: `url(${
          !!image.childImageSharp ? image.childImageSharp.fluid.src : image
        })`,
      }}
    >
      <h2
        className="has-text-weight-bold is-size-1"
        style={{
          boxShadow: '0.5rem 0 0 #f40, -0.5rem 0 0 #f40',
          backgroundColor: '#f40',
          color: 'white',
          padding: '1rem',
        }}
      >
        {title}
      </h2>
    </div>
    <section className="section section--gradient">
      <div className="container">
        <div className="section">
          <div className="columns">
            <div className="column is-7 is-offset-1">
              <h1 className="has-text-weight-semibold is-size-2">{heading}</h1>
              <p>{description}</p>
            </div>
          </div>
          <hr/>
          <div className="columns">
            <div className="column is-10 is-offset-1">

              <h2 className="has-text-weight-semibold is-size-2">
                {faq.title}
              </h2>
              <FAQs data={faq.questions} />
            </div>
          </div>
        </div>
        <hr/>

      </div>
    </section>
  </div>
)

HelpPageTemplate.propTypes = {
  helmet: PropTypes.object,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  faq: PropTypes.shape({
    title: PropTypes.string,
    questions: PropTypes.array,
  }),
}

const HelpPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <HelpPageTemplate
        helmet={
          <Helmet titleTemplate="%s | Advance Auto Movers">
            <title>{`${frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${frontmatter.heading}`}
            />
          </Helmet>
        }
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        description={frontmatter.description}
        faq={frontmatter.faq}
      />
    </Layout>
  )
}

HelpPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default HelpPage

export const helpPageQuery = graphql`
  query HelpPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        description
        faq {
          title
          questions {
            question
            answer
          }
        }
      }
    }
  }
`
